import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";

const ProjectFullWidthBanner = ({ image }) => {
	const gatsbyImageData = image.localFile?.childImageSharp?.gatsbyImageData;
	if(!gatsbyImageData) {
		return null;
	}

	const backgroundImage = convertToBgImage(gatsbyImageData);

	return (
		<div className="project-full-width-banner">
			<div className="project-full-width-banner__wrapper">
				<BackgroundImage
					Tag="div"
					className="project-full-width-banner__image"
					style={{
						"--aspect-ratio": backgroundImage.fluid.aspectRatio
					}}
					{...backgroundImage}
				/>
			</div>
		</div>
	);
};

ProjectFullWidthBanner.propTypes = {
	image: PropTypes.object.isRequired
};

export default ProjectFullWidthBanner;
