import React from "react";
import PropTypes from "prop-types";

const ProjectSmallTextBlocks = ({ title, textBlocks }) => {
	return (
		<section className="project-small-text-blocks">
			<div className="project-small-text-blocks__wrapper container container--medium">
				{title && (
					<div
						className="project-small-text-blocks__title fade-section-title fade-section-title--medium"
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				)}

				<div className="project-small-text-blocks__items">
					{textBlocks.map(({ title, description }, index) => (
						<div key={index} className="project-small-text-blocks__item">
							<h3 className="project-small-text-blocks__item-title">
								{title}
							</h3>

							<div className="project-small-text-blocks__item-description" dangerouslySetInnerHTML={{ __html: description }}/>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

ProjectSmallTextBlocks.propTypes = {
	title: PropTypes.string,
	textBlocks: PropTypes.array.isRequired
};

export default ProjectSmallTextBlocks;
