import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ProjectTextInfo = ({ columns, verticalPadding }) => {
	const sizeModifiers = {
		large: "project-text-info__column--large"
	};

	const verticalPaddingModifiers = {
		small: "project-text-info__wrapper--small-vertical-padding"
	};

	return (
		<section className="project-text-info">
			<div
				className={
					classNames("project-text-info__wrapper container container--medium", verticalPaddingModifiers[verticalPadding])
				}
			>
				<div className="project-text-info__columns">
					{columns.map(({ title, description, columnSize }, index) => {
						return (
							<div
								key={index}
								className={
									classNames("project-text-info__column", sizeModifiers[columnSize])
								}
							>
								<h2 className="project-text-info__item-title medium-title">
									{title}
								</h2>

								<div
									className="project-text-info__item-content"
									dangerouslySetInnerHTML={{
										__html: description
									}}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

ProjectTextInfo.propTypes = {
	columns: PropTypes.array.isRequired,
	verticalPadding: PropTypes.string.isRequired
};

export default ProjectTextInfo;
