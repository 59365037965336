import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Image } from "gatsby-plugin-image";

const ProjectFeature = ({ projectTitle, title, description, leftImage, rightImage }) => {
	const TextContentMarkup = (
		<>
			{title && (
				<h2 className="project-feature__title medium-title">
					{title}
				</h2>
			)}

			{description && (
				<p className="project-feature__description">
					{description}
				</p>
			)}
		</>
	);

	return (
		<section className="project-feature">
			<div className="project-feature__wrapper container container--medium">
				<div className="project-feature__content hide-tablet-and-up">
					{TextContentMarkup}
				</div>

				<div className="project-feature__columns">
					<div className="project-feature__column project-feature__column--left">
						<Image
							className="project-feature__image project-feature__image--left"
							image={leftImage.localFile?.childImageSharp?.gatsbyImageData}
							alt={leftImage.altText || projectTitle}
							title={leftImage.altText || projectTitle}
						/>
					</div>

					<div className="project-feature__column project-feature__column--right">
						<div className="project-feature__content hide-mobile">
							{TextContentMarkup}
						</div>

						<Image
							className="project-feature__image project-feature__image--right"
							image={rightImage.localFile?.childImageSharp?.gatsbyImageData}
							alt={rightImage.altText || projectTitle}
							title={leftImage.altText || projectTitle}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

ProjectFeature.propTypes = {
	projectTitle: PropTypes.string.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
	leftImage: PropTypes.object.isRequired,
	rightImage: PropTypes.object.isRequired
};

export default ProjectFeature;
