import React, { useRef } from "react";

const withScrollDown = WrappedComponent => {
	return function WithScrollDown({ getReference: getReferenceFromAnotherHOC = () => {}, ...props }) {
		const element = useRef(null);

		const scrollDown = () => {
			if(!element.current) {
				return null;
			}

			const header = document.getElementById("site-header");
			const headerHeight = +header?.offsetHeight - +( header &&window.getComputedStyle(header).getPropertyValue("--offset"));

			const [rects] = element.current.getClientRects();

			window.scrollTo({
				top: rects.height + element.current.offsetTop - headerHeight,
				behavior: "smooth"
			});
		};

		const getReference = ref => {
			element.current = ref;

			getReferenceFromAnotherHOC(ref);
		}

		return (
			<WrappedComponent
				getReference={getReference}
				scrollDown={scrollDown}
				{...props}
			/>
		);
	}
};

export default withScrollDown;
