import React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import ProjectSummary from "../sections/Project/ProjectSummary";
import ProjectGallery from "../sections/Project/ProjectGallery";
import ProjectSecondaryBanner from "../sections/Project/ProjectSecondaryBanner";
import ProjectTextBlock from "../sections/Project/ProjectTextBlock";
import ProjectFullWidthBanner from "../sections/Project/ProjectFullWidthBanner";
import ProjectSmallTextBlocks from "../sections/Project/ProjectSmallTextBlocks";
import ProjectTextInfo from "../sections/Project/ProjectTextInfo";
import ProjectBanner from "../sections/Project/ProjectBanner";
import ProjectFeature from "../sections/Project/ProjectFeature";
import ProjectMeta from "../sections/Project/ProjectMeta";
import NextTheme from "../sections/Themes/NextTheme";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import {graphql, useStaticQuery} from "gatsby";
import PageHeader from "../components/PageHeader";

const Theme = ({ pageContext }) => {
	const { theme, nextTheme } = pageContext;

	const getSection = ({ fieldGroupName, ...section }, index) => {
		const key = index;

		switch (fieldGroupName) {
			case 'shopifyTheme_Acftheme_Sections_ThemeSummary': {
				return (
					<ProjectSummary
						key={key}
						theme
						withManual
						technologies={theme.technologies?.nodes}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeGallery': {
				return (
					<ProjectGallery
						key={key}
						projectTitle={theme.title}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeSecondaryBanner': {
				return (
					<ProjectSecondaryBanner
						key={key}
						projectTitle={theme.title}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeSingleTextBlock': {
				return (
					<ProjectTextBlock
						key={key}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeFullWidthBanner': {
				return (
					<ProjectFullWidthBanner
						key={key}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeSmallTextBlocks': {
				return (
					<ProjectSmallTextBlocks
						key={key}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeTextInfo': {
				return (
					<ProjectTextInfo
						key={key}
						{...section}
					/>
				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeBanner': {
				const headerOnBanner = theme.page_header?.position === 'on';
				const headerBelowBanner = theme.page_header?.position === 'below' || theme.page_header?.position === 'bellow';

				const titleBelowBannerMarkup = (
					headerBelowBanner &&
					theme.page_header?.title
				)
					? <PageHeader {...theme.page_header} isGeneral={true} />
					: null;

				return (
					<React.Fragment key={key}>
						<ProjectBanner
							projectTitle={theme.title}
							pageHeader={headerOnBanner ? theme.page_header : null}
							{...section}
						/>

						<ProjectMeta type={"shopify-themes"}/>

						{headerBelowBanner && titleBelowBannerMarkup}
					</React.Fragment>

				);
			}

			case 'shopifyTheme_Acftheme_Sections_ThemeFeature': {
				return (
					<ProjectFeature
						key={key}
						projectTitle={theme.title}
						{...section}
					/>
				);
			}

			default: {
				return null;
			}
		}
	};

	const getSections = () => {
		if(!Array.isArray(theme.acfTheme?.sections)) {
			return null;
		}
		return theme.acfTheme.sections.map(getSection);
	}

	const pageTitle = theme?.acfTheme?.metaData?.title || "Theme";
	const pageDescription = theme?.acfTheme?.metaData?.description || "Shopify theme description";
	const pageKeywords = "Shopify theme, UTD, customizable theme, premium theme, mobile-first theme, e-commerce theme, ADA compliant, cross-browser compatibility, cross-platform compatibility, conversion optimization, sales boosting, unique features, customization options."

	useBeaconWidget(beaconWidgetIds.THEME);

	const {site} = useStaticQuery(
		graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        author
                    }
                }
            }
        `
	);

	const microdata = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": theme.title,
		"image": [
			site?.siteMetadata?.siteUrl + theme.acfTheme.squaredImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
		],
		"author": [{
			"@type": "Organization",
			"name": site?.siteMetadata?.title,
			"url": site?.siteMetadata?.siteUrl
		}]
	};

	return (
		<DefaultLayout preFooterSections={<NextTheme {...nextTheme} />}>
			<DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeywords} microdata={microdata}/>

			{getSections()}
		</DefaultLayout>
	)
};

export default Theme;
