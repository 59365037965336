import React from "react";
import {getImage} from "gatsby-plugin-image";
import PageHeader from "./PageHeader";

const VideoSimpleBanner = ({
	title,
	showTitle = true,
	html5VideoUrl,
	html5VideoType,
	poster,
	pageHeader,
}) => {
	const posterImage = getImage(poster.localFile);

	const headerOnBanner = pageHeader?.position === 'on';
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';
	const bannerTitleProps = headerOnBanner && pageHeader?.title
		? pageHeader
		: {};

	const bannerTitleMarkup = (
		<PageHeader
			{...bannerTitleProps}
			title={headerOnBanner && pageHeader?.title ? pageHeader?.title : title}
			implemented={true}
			className={"section-title section-title--large video-simple-banner__title"}
			isGeneral={headerOnBanner}
		/>
	);

	const showHeader = headerOnBanner || (!headerOnBanner && showTitle);

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;

	return (
		<>
			<div className="video-simple-banner">
				{html5VideoUrl && (
					<video
						className="video-simple-banner__video"
						width="600"
						height="400"
						autoPlay
						loop
						muted
						playsInline
						poster={posterImage?.images?.fallback?.src}
					>
						<source src={html5VideoUrl} type={html5VideoType} />
					</video>
				)}

				{showHeader && bannerTitleMarkup}
			</div>

			{titleBelowBannerMarkup}
		</>
	);
}

export default VideoSimpleBanner;
