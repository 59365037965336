import React, { useEffect, useRef, useState } from "react";
import isTouch from "../helpers/isTouch";
import throttle from "../helpers/throttle";

const withFullHeightScreen = WrappedComponent => {
	return function WithFullHeightScreen({ getReference: getReferenceFromAnotherHOC = () => {}, ...props }) {
		const element = useRef(null);

		const getMinHeight = () => {
			if(!isTouch()) {
				return "";
			}

			return `${window.innerHeight}px`;
		};

		const [minHeight, setMinHeight] = useState(getMinHeight());

		useEffect(() => {
			let innerWidth = window.innerWidth;

			const handleResize = throttle(() => {
				if(innerWidth === window.innerWidth) {
					return null;
				}

				setMinHeight(getMinHeight());
				innerWidth = window.innerWidth;
			}, 100);

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, [element]);

		const getReference = ref => {
			element.current = ref;

			getReferenceFromAnotherHOC(ref);
		}

		return (
			<WrappedComponent
				getReference={getReference}
				minHeight={minHeight}
				{...props}
			/>
		);
	}
};

export default withFullHeightScreen;
