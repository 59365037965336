import React from "react";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const ProjectSecondaryBanner = ({ bottomOverlayBackground, topOverlayBackground, image, projectTitle }) => {
	const altText = image.altText || projectTitle;

	return (
		<section
			className="project-secondary-banner"
			style={{
				"--top-overlay-background": topOverlayBackground,
				"--bottom-overlay-background": bottomOverlayBackground
			}}
		>
			<div className="project-secondary-banner__wrapper container">
				<div className="project-secondary-banner__image-wrapper">
					<Image
						className="project-secondary-banner__image"
						image={image.localFile?.childImageSharp?.gatsbyImageData}
						alt={altText || ""}
						title={altText || ""}
					/>
				</div>
			</div>
		</section>
	);
};

ProjectSecondaryBanner.propTypes = {
	bottomOverlayBackground: PropTypes.string,
	topOverlayBackground: PropTypes.string,
	image: PropTypes.object.isRequired,
	projectTitle: PropTypes.string.isRequired
};

export default ProjectSecondaryBanner;
