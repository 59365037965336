import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import {convertToBgImage} from "gbimage-bridge";
import {motion} from "framer-motion";

import withScrollDown from "../../hocs/withScrollDown";
import withFullHeightScreen from "../../hocs/withFullHeightScreen";
import VideoSimpleBanner from "../../components/VideoSimpleBanner";
import IconScrollChevron from "../../icons/IconScrollChevron";
import {bannerSubtitleAnimation} from "../../settings/generalAnimations";
import PageHeader from "../../components/PageHeader";

const MEDIA_TYPES = {
    VIDEO: "video",
    IMAGE: "image"
}

const ProjectBanner = ({
   scrollButtonText,
   projectTitle,
   showTitle = true,
   subtitle,
   mediaType,
   image,
   video,
   videoPoster,
   scrollDown,
   getReference,
   minHeight,
   pageHeader,
}) => {
    const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData;
    const gatsbyVideoPosterData = videoPoster?.localFile?.childImageSharp?.gatsbyImageData;

    if (mediaType === MEDIA_TYPES.IMAGE && !gatsbyImageData) {
        return null;
    } else if (mediaType === MEDIA_TYPES.VIDEO && !video && !gatsbyVideoPosterData) {
        return null;
    }

    const backgroundImage = convertToBgImage(gatsbyImageData);

    const headerOnBanner = pageHeader?.position === 'on';
    const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';
    const showHeader = headerOnBanner || (!headerOnBanner && showTitle);
    const bannerTitleProps = headerOnBanner && pageHeader?.title
        ? pageHeader
        : {};

    const bannerTitleMarkup = (
        <PageHeader
            {...bannerTitleProps}
            title={headerOnBanner && pageHeader?.title ? pageHeader?.title : projectTitle}
            implemented={true}
            className={"project-banner__title"}
            isGeneral={headerOnBanner}
        />
    );

    const titleBelowBannerMarkup = (
        headerBelowBanner &&
        pageHeader?.title &&
        !!mediaType === MEDIA_TYPES.VIDEO
    )
        ? <PageHeader {...pageHeader} isGeneral={true} />
        : null;

    return (
        <>
            {
                mediaType === MEDIA_TYPES.VIDEO ? <VideoSimpleBanner
                    showTitle={showTitle}
                    poster={videoPoster}
                    title={projectTitle}
                    html5VideoUrl={video?.mediaItemUrl}
                    htmlVideoType={video?.mimeType}
                    pageHeader={pageHeader}
                /> : <section
                    className="project-banner"
                    ref={getReference}
                    style={{
                        minHeight: minHeight
                    }}
                >
                    <BackgroundImage
                        Tag="div"
                        className="project-banner__container"
                        {...backgroundImage}
                    >
                        <div className="project-banner__content-wrapper container">
                            <div className="project-banner__content">
                                {showHeader && bannerTitleMarkup}

                                {subtitle && (
                                    <motion.h2
                                        className="project-banner__subtitle"
                                        {...bannerSubtitleAnimation}
                                    >
                                        {subtitle}
                                    </motion.h2>
                                )}
                            </div>

                            {scrollButtonText && (
                                <button className="scroll-button" type="button" onClick={scrollDown}>
                                    <IconScrollChevron/> {scrollButtonText}
                                </button>
                            )}
                        </div>
                    </BackgroundImage>
                </section>
            }

            {titleBelowBannerMarkup}
        </>
    )
}

ProjectBanner.propTypes = {
    image: PropTypes.object,
    video: PropTypes.object,
    videoPoster: PropTypes.object,
    projectTitle: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    subtitle: PropTypes.string,
    scrollButtonText: PropTypes.string,
    minHeight: PropTypes.string.isRequired,
    scrollDown: PropTypes.func.isRequired
};

export default withFullHeightScreen(withScrollDown(ProjectBanner));
