import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ProjectTextBlock = ({ title, description, blockAlignment }) => {
	const alignmentModifier = {
		right: "project-text-block--right"
	}

	return (
		<section
			className={
				classNames('project-text-block', alignmentModifier[blockAlignment])
			}
		>
			<div className="project-text-block__wrapper container container--medium">
				<div className="project-text-block__content">
					<h2 className="project-text-block__title medium-title">
						{title}
					</h2>

					<p className="project-text-block__description">
						{description}
					</p>
				</div>
			</div>
		</section>
	);
};

ProjectTextBlock.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	blockAlignment: PropTypes.string.isRequired
};

export default ProjectTextBlock;
