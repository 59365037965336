import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import PropTypes from "prop-types";

const NextTheme = ({ showSection, theme, label }) => {
	if(!showSection || !theme) {
		return null;
	}

	const gatsbyImageData = theme.acfTheme?.mainImage?.localFile?.childImageSharp?.gatsbyImageData;
	if(!gatsbyImageData) {
		return null;
	}

	const mainImageFluid = convertToBgImage(gatsbyImageData);

	return (
		<section className="next-project">
			<BackgroundImage
				className="next-project__wrapper"
				{...mainImageFluid}
			>
				<Link
					to={`/shopify-themes/${theme.slug}/`}
					className="next-project__link-overlay"
					aria-label={`Visit our next theme - ${theme.title}`}
				/>

				<div className="next-project__content container">
					{label && (
						<p className="next-project__label">
							{label}
						</p>
					)}

					<h2 className="next-project__title">
						{theme.title}
					</h2>
				</div>
			</BackgroundImage>
		</section>
	);
};

NextTheme.propTypes = {
	theme: PropTypes.object.isRequired,
	showSection: PropTypes.bool.isRequired,
	label: PropTypes.string
};

export default NextTheme;
